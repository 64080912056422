import React from "react";
import { DataGrid, frFR, GridToolbar, GridToolbarExport, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/fr";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate, useLocation } from "react-router-dom";

function TableauGrid(props) {
  const { column, dataRows, idRows, showQuickFilter, isOnboarding, hasDate, fileName = 'data' } = props;
  const navigate = useNavigate();

  function NoRowsOverlay() {
    return <div className="h-full flex items-center justify-center">Pas de résultat.</div>;
  }
  function CustomToolbar() {
    return (
      <div className='flex justify-between p-2'>
        <GridToolbarExport csvOptions={{
          fileName: fileName,
          delimiter: ';',
          utf8WithBom: true,
        }} />
        <GridToolbarQuickFilter />
      </div>
    );
  }
  return (
    <>
      <DataGrid
        rows={dataRows ? dataRows : []}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay,
        }}
        getRowId={(row) => (row.id ? row.id : row.date ? row.date : idRows)}
        componentsProps={{
          toolbar: {
            showQuickFilter: showQuickFilter,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          sorting: {
            sortModel: [{ field: `${hasDate ? "date" : "id"}`, sort: "desc" }],
          },
          pinnedColumns: { left: ["id"] },
        }}
        columns={column}
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter

        autoPageSize
        disableSelectionOnClick={true}
      //getRowClassName={(params) => `text-slate-800 dark:text-slate-100`}
      // getCellClassName={(params) => `text-slate-800 dark:text-slate-100`}
      />
    </>
  );
}

export default TableauGrid;
