import React from 'react'
import image1 from "../asset/img/screen1.png";
import S2 from "../asset/img/screen2.png";


const Paragraph = ({ children }) => (
    <p className="text-justify  text-xs md:text-sm">
        {children}
    </p>
)

const List = ({ children }) => (
    <li className="pl-4 text-xs md:text-sm">
        {children}
    </li>
)

const Title = ({ children }) => (
    <div className="font-bold text-sm md:text-lg">
        {children}
    </div>
)

function CguScreen() {
    return (
        <div className="container mx-auto px-4 bg-slate-100 min-h-screen space-y-5 py-4  md:px-52 ">

            <h3 className="text-center font-bold md:text-2xl">
                CONDITIONS D’UTILISATION
            </h3>
            <div>
                <div className="space-y-2 ">
                    <Title>
                        1. Acceptation des conditions d'utilisation
                    </Title>
                    <Paragraph >
                        Les conditions d'utilisation suivantes (les "Conditions d'Utilisation"), ainsi que la politique de
                        confidentialité (la "Politique de Confidentialité"), toutes les autres politiques ainsi que tous les documents
                        référencés ici constituent un accord juridiquement contraignant (l'"Accord") entre Evaco Ltd ou ses filiales
                        (la " Compagnie ") et vous ("Vous" ou l'"Utilisateur") régissant l'utilisation de l'application mobile FastPay
                        (la "Plateforme").

                    </Paragraph>

                    <Paragraph >En visitant ou en utilisant la Plateforme, l'Utilisateur accepte d'être lié par l'Accord qui constitue:</Paragraph>
                    <ol className="text-xs md:text-sm">
                        <List>a. les Conditions d'Utilisation;</List>
                        <List>b. la Politique de Confidentialité;</List>
                        <List>c. toutes autres conditions supplémentaires et les conditions supplémentaires communiquées à l'Utilisateur au moment concerné.</List>
                    </ol>

                    <Paragraph >La Compagnie se réserve le droit de mettre à jour ou de modifier l'Accord à tout moment, avec ou sans
                        notification à l'Utilisateur. Il relève de la responsabilité des Utilisateurs de consulter régulièrement la
                        Plateforme afin de prendre connaissance de ces modifications.</Paragraph>

                    <Paragraph >
                        La poursuite de l'utilisation de la Plateforme par l'Utilisateur après la publication des modifications signifie
                        qu'il accepte l'Accord.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        2. Informations générales
                    </Title>
                    <Paragraph >
                        La Compagnie propose la Plateforme en tant que marché de l'emploi en ligne destiné à toute personne à
                        la recherche d'un emploi à quelque titre que ce soit.
                    </Paragraph>

                    <Paragraph >
                        L'Utilisateur ne peut utiliser la Plateforme qu'à des fins licites dans le cadre de l'utilisation prévue et
                        acceptable de la Plateforme.
                    </Paragraph>
                </div>

            </div>


            <div>
                <div className="space-y-2">
                    <Title>
                        3. Offres d'emplois
                    </Title>
                    <Paragraph >
                        La Compagnie n'assume aucune responsabilité, et décline toute responsabilité quant au contenu, à
                        l'exactitude, à l'exhaustivité, à la légalité, à la fiabilité ou à la disponibilité de toute offre d'emploi ou de
                        toute autre donnée et/ou information disponible sur la Plateforme.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        4. Profils d'utilisateurs et utilisation de la Plateforme

                    </Title>
                    <Paragraph>
                        Les Utilisateurs doivent compléter le processus d'enregistrement en fournissant des informations
                        actuelles, complètes et exactes, comme le demande le formulaire d'enregistrement applicable sur la
                        Plateforme.
                    </Paragraph>

                    <Paragraph >
                        Les Utilisateurs sont entièrement responsables du maintien de la confidentialité de leur mot de passe et
                        de leur compte. Les Utilisateurs sont également entièrement responsables de toutes les activités qui se
                        déroulent sur leur compte.
                    </Paragraph>

                    <Paragraph>
                        Les Utilisateurs acceptent par la présente d'informer immédiatement la Compagnie de toute utilisation
                        non autorisée de leur compte ou de toute autre violation de la sécurité.
                    </Paragraph>
                    <Paragraph>
                        Les Utilisateurs ne peuvent créer et publier du contenu qu'à des fins compatibles avec l'Accord et qui ne
                        sont pas illégales ou qui n'enfreignent pas les lois en vigueur.
                    </Paragraph>

                    <Paragraph>
                        Les Utilisateurs ne peuvent pas créer de contenu incluant des informations confidentielles ou exclusives,
                        de la propriété intellectuelle, des données personnelles relatives à des tiers (y compris le nom d'une
                        personne ou d'autres informations permettant d'identifier une personne vivante) ou des secrets
                        commerciaux, ou qui enfreint le contrat d'emploi de l'Utilisateur.

                    </Paragraph>

                    <Paragraph>L'Utilisateur reconnaît et accepte par la présente que :</Paragraph>
                    <ol className="text-xs md:text-sm flex flex-col space-y-2">
                        <List>a. en publiant un curriculum vitae, des informations, des données, des images, des photos, du
                            matériel, de la documentation ou des informations relatives à sa candidature sur la Plateforme,
                            il autorise la Compagnie à rendre son profil disponible pour les offres d'emploi correspondantes
                            ;</List>
                        <List>b. la Compagnie se fie à l'exactitude et à la complétude de toutes les informations fournies et, à ce
                            titre, il/elle garantit et déclare que toutes les informations fournies sur la Plateforme sont
                            exactes, complètes et non trompeuses ;</List>

                        <List>c. il revient à l'Utilisateur de vérifier la validité des offres d'emploi avant de s'engager ou de
                            prendre toute mesure susceptible d'affecter, entre autres, sa situation professionnelle actuelle ;</List>

                        <List>d. le dépôt d'une candidature sur la Plateforme est une étape préliminaire à la recherche d'un
                            emploi. Elle n'oblige pas la Compagnie à contacter un Utilisateur en relation avec une
                            candidature, ni à lui proposer un emploi ;</List>

                        <List>e. il/elle ne peut à aucun moment utiliser le compte d'une autre personne sans l'autorisation du
                            titulaire du compte ;</List>

                        <List>e. tout lien donné sur la Plateforme est fourni dans l'intérêt ou pour la facilité de l'Utilisateur
                            uniquement et ne représente ou n'implique aucune approbation par la Compagnie de ces sites
                            web liés et la Compagnie ne peut être tenue responsable de toute perte ou dommage pouvant
                            découler de l'utilisation de ces sites web tiers ou d'accords conclus suite à l'utilisation de ces
                            sites web tiers ; et
                        </List>

                        <List>g. la Compagnie se réserve le droit de suspendre ou de résilier l'accès et l'utilisation de tout
                            Utilisateur à tout moment si la Compagnie détermine que l'Utilisateur est en violation de
                            l'Accord. Toute résiliation prendra effet sans préjudice de tout dommage que la Compagnie
                            pourrait réclamer à la suite d'une violation ou d'une condition prévue par ailleurs dans l'Accord.
                        </List>
                    </ol>

                    <Paragraph>La Compagnie ne vérifie pas, n'approuve pas et ne se porte pas garante d'un Utilisateur. Elle ne fait aucune
                        déclaration concernant les qualifications de l'utilisateur et ne sanctionne pas les Utilisateurs potentiels.</Paragraph>


                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        5. Politique de Protection des Données et de Confidentialité
                    </Title>
                    <Paragraph>
                        La Compagnie s'engage à protéger la confidentialité de l'Utilisateur en ligne. Veuillez consulter la
                        Politique de Confidentialité du Groupe Evaco pour plus de détails sur l'engagement ferme du
                        Groupe Evaco en matière de protection de la confidentialité.
                    </Paragraph>


                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        6. Licence du contenu de l'Utilisateur
                    </Title>
                    <Paragraph>
                        En soumettant un contenu, vous accordez par la présente à la Compagnie et à ses filiales une licence
                        mondiale, illimitée, irrévocable, perpétuelle, non exclusive, transférable et libre de redevances pour
                        utiliser, copier, faire, reproduire, afficher, exécuter, distribuer, accorder des sous-licences, vendre,
                        supprimer, éditer, préparer des œuvres dérivées à partir du contenu, d'analyser et d'exploiter de toute
                        autre manière le contenu aux fins de la gestion de la Plateforme et de la fourniture des services (y compris
                        en le compilant avec le contenu d'autres Utilisateurs et en l'anonymisant pour effectuer des analyses
                        statistiques à des fins de comparaison ou de référence), dans la mesure où les lois applicables le
                        permettent
                    </Paragraph>

                    <Paragraph>
                        L'Utilisateur reconnaît que la Compagnie est titulaire des droits de propriété intellectuelle sur les œuvres
                        dérivées à partir du contenu (que ce soit conjointement ou uniquement) et que l'autorisation écrite
                        préalable de la Compagnie est requise pour toute utilisation ou suppression du contenu. L'utilisation du
                        contenu ne vous confère aucun droit de propriété sur le contenu
                    </Paragraph>



                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        7. Responsabilité du contenu de l'Utilisateur
                    </Title>
                    <Paragraph>
                        En soumettant un contenu sur cette Plateforme, l'Utilisateur reconnaît que toute utilisation, affichage,
                        copie ou reproduction qu'il/elle fait de la marque ou du logo d'une entreprise ou l'inclusion du texte d'un
                        employeur ou d'un tiers ou d'autres contenus (y compris par un lien hypertexte) constitue une utilisation
                        des droits de propriété intellectuelle de tiers, et l'Utilisateur convient qu'il est entièrement responsable
                        de s'assurer que son contenu est autorisé.
                    </Paragraph>

                    <Paragraph>
                        Si un Utilisateur a un retour d'information ou une plainte concernant l'exactitude d'un contenu spécifique,
                        l'Utilisateur peut signaler ce contenu en envoyant un courriel à l'adresse suivante :
                        <a href="mailto:fastpay@evacogroup.com"> fastpay@evacogroup.com</a>
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        8. Utilisation illégale et interdite
                    </Title>
                    <Paragraph>
                        Les Utilisateurs ne peuvent utiliser la Plateforme, ou les services proposés par la Plateforme, qu'à des fins
                        licites de recherche d'emploi, d'aide à la carrière, ou de recrutement de personnel(s).
                    </Paragraph>

                    <Paragraph>
                        Les Utilisateurs ne doivent en aucun cas chercher à porter atteinte à la sécurité de la Plateforme ou de
                        toute information soumise ou disponible par son intermédiaire. En particulier, mais sans s'y limiter, les
                        Utilisateurs ne doivent pas chercher à accéder, modifier ou supprimer toute information sur la Plateforme
                        à laquelle ils n'ont pas un accès autorisé, chercher à surcharger le système de la Plateforme par le biais de
                        spamming ou de flooding, prendre toute mesure ou utiliser tout dispositif, routine ou logiciel pour planter,
                        retarder, endommager ou interférer de toute autre manière avec le fonctionnement de la Plateforme ou
                        tenter de déchiffrer, désassembler ou modifier tout logiciel, codage ou information compris dans la
                        Plateforme.
                    </Paragraph>

                    <Paragraph>
                        Les Utilisateurs sont également tenus de s'assurer que toutes les informations, données et fichiers sont
                        exempts de virus ou d'autres routines ou moteurs susceptibles d'endommager ou d'interférer avec un
                        système ou des données avant d'être soumis sur la Plateforme.
                    </Paragraph>

                    <Paragraph>
                        Il est interdit aux Utilisateurs de faire usage de la Plateforme et/ou des services fournis par la Plateforme
                        d'une manière considérée comme étant en concurrence avec les activités commerciales de la Compagnie
                        (telles que déterminées par la Compagnie à sa seule discrétion).
                    </Paragraph>

                    <Paragraph>
                        La Compagnie se réserve le droit de retirer de la Plateforme toute information, y compris tout contenu
                        fourni par l'Utilisateur, à sa seule discrétion, à tout moment et pour quelque raison que ce soit, sans
                        préavis ni explication.
                    </Paragraph>
                </div>

            </div>


            <div>
                <div className="space-y-2">
                    <Title>
                        9. Responsabilité de la Compagnie
                    </Title>
                    <Paragraph>
                        La Compagnie ne garantit pas que la Plateforme sera disponible de manière ininterrompue, et la
                        Compagnie n'accepte aucune responsabilité en ce qui concerne les pertes ou les dommages découlant de
                        l'indisponibilité de la Plateforme.
                    </Paragraph>

                    <Paragraph>
                        La Compagnie ne sera pas responsable des pertes que les Utilisateurs pourraient subir du fait de
                        l'utilisation par une autre personne de leur mot de passe ou de leur compte, que ce soit à leur insu ou
                        non. Toutefois, un Utilisateur pourrait être tenu responsable des pertes subies par la Compagnie ou une
                        autre partie en raison de l'utilisation par une autre personne du compte ou du mot de passe de
                        l'Utilisateur en raison de sa négligence ou de la violation du présent Accord.
                    </Paragraph>

                    <Paragraph>
                        La Compagnie ne sera en aucun cas responsable des pertes ou dommages, y compris les dommages
                        indirects ou consécutifs, ou de tout autre dommage découlant de l'utilisation ou de la perte d'utilisation,
                        de données ou de bénéfices, que ce soit dans le cadre d'une action contractuelle ou d'une négligence,
                        découlant de ou en rapport avec l'utilisation de la Plateforme, y compris les modifications apportées au
                        contenu de la Plateforme par une tierce partie. L'accès et l'utilisation de la Plateforme par les Utilisateurs
                        se font à leurs risques et périls.
                    </Paragraph>

                    <Paragraph>
                        Les Utilisateurs reconnaissent qu'en accédant à la Plateforme, ils peuvent tomber sur des contenus qu'ils
                        jugent nuisibles, offensants, menaçants, indécents ou répréhensibles et ils reconnaissent que la
                        Compagnie n'a aucune responsabilité envers eux pour de tels contenus, y compris le langage explicite et
                        d'autres contenus potentiellement offensants.
                    </Paragraph>

                    <Paragraph>
                        La Compagnie n'approuve pas et n'est pas responsable des opinions, conseils, informations ou
                        déclarations figurant dans le contenu d'un Utilisateur. Dans toute la mesure permise par la loi, la
                        Compagnie ne sera en aucun cas responsable de l'utilisation, de la mauvaise utilisation ou de la confiance
                        accordée au contenu d'un Utilisateur, de toute réclamation faite en relation avec le présent Accord, ou
                        de tout dommage, qu'il soit direct, indirect, accessoire, consécutif, spécial ou punitif, et qu'il résulte de
                        l'utilisation, de la mauvaise utilisation ou de la confiance accordée au contenu d'un Utilisateur, de
                        dommages causés par des informations figurant dans le contenu de l'Utilisateur ou de dommages
                        encourus en raison de l'utilisation de tout lien fourni dans le contenu de l'Utilisateur.
                    </Paragraph>

                    <Paragraph>
                        Nous excluons expressément notre responsabilité pour toute perte ou tout dommage résultant de
                        l'utilisation de la Plateforme et/ou des services par un Utilisateur en violation de notre Accord, que la
                        Plateforme et/ou les services offerts dans le cadre de la Plateforme soient modérés ou non. Toute mesure
                        que nous pourrions prendre à l'égard d'un Utilisateur ou d'un contenu, par exemple la suppression,
                        l'édition ou le refus de publier, ne constitue ni une reconnaissance de responsabilité de notre part ni une
                        indication de la violation ou non d'une norme, d'une disposition légale ou d'une condition par un
                        Utilisateur.
                    </Paragraph>

                    <Paragraph>
                        En accédant à cette Plateforme, les Utilisateurs reconnaissent que la Compagnie n'est pas responsable de
                        l'utilisation abusive, alléguée ou non, des droits de propriété intellectuelle de tiers par les Utilisateurs de
                        cette Plateforme.
                    </Paragraph>


                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        10. Droit applicable et juridiction
                    </Title>
                    <Paragraph>
                        L'Accord et toute question qui y est liée sont régis et interprétés conformément aux lois de la République
                        de Maurice.
                    </Paragraph>

                    <Paragraph>
                        Les tribunaux de la République de Maurice sont exclusivement compétents pour régler tout litige
                        découlant de l'utilisation de la Plateforme ou de l'Accord, ou y étant lié.
                    </Paragraph>

                </div>

            </div>



            <h3 className="text-center font-bold md:text-2xl">
                POLITIQUE DE CONFIDENTIALITÉ
            </h3>

            <Paragraph>
                Evaco Ltd, numéro d'enregistrement d’entreprise C06040742, situé à Rivière Citron, 20101
                Arsenal, Maurice, est responsable de la plupart des e-mails et de l'infrastructure informatique du
                groupe.
            </Paragraph>

            <Paragraph>
                Evaco Ltd est la Compagnie holding du groupe Evaco, un groupe diversifié qui opère à travers
                diverses filiales, notamment :
            </Paragraph>

            <div>
                <ul>
                    <List>• Aquamarine Watersport Ltd </List>
                    <List>• Archipel des Saveurs Ltd</List>
                    <List>• Creative Commercial Properties Ltd</List>
                    <List>• Creative Local Properties Ltd</List>
                    <List>• Creative Properties Ltd</List>
                    <List>• Creative Senior Properties Ltd</List>
                    <List>• Evaco Beach Club Limited</List>
                    <List>• Evaco Corporate Services Ltd</List>
                    <List>• Evaco Foundation</List>
                    <List>• Evaco Marketing Ltd</List>
                    <List>• Evaco Property Investment Ltd</List>
                    <List>• Evaco Property Ltd</List>
                    <List>• Evaco Restaurant Holding Ltd</List>
                    <List>• Evajet Ltd</List>
                    <List>• Evolution Architects Ltd</List>
                    <List>• FairStone Ltd (ex Evaco Construction Ltd)</List>
                    <List>• FairStone Support Services Ltd</List>
                    <List>• FC Property Limited</List>
                    <List>• Fine Line Contracting Ltd</List>
                    <List>• Highlands Hills Properties Ltd</List>
                    <List>• Histia Services Ltd (ex Evaco Holiday Resorts Ltd)</List>
                    <List>• IDEA Consultants Ltd (ex Innovative Design Engineering and Architecture Ltd)</List>
                    <List>• Intense Expérience Ltd</List>
                    <List>• Isle de France Foundation</List>
                    <List>• Le Clos du Littoral Ltée</List>
                    <List>• Le Clos du Littoral Phase II Ltd</List>
                    <List>• Le Clos du Littoral phase III Ltd</List>
                    <List>• Le Clos du Littoral Phase IV Ltd</List>
                    <List>• Le Clos du Littoral Senior Properties Ltd</List>
                    <List>• Le Domaine des Alizees Ltée</List>
                    <List>• Les Villas Athena Ltée</List>
                    <List>• Mereo Ltd</List>
                    <List>• Nautilus Property Ltd</List>
                    <List>• Secret Resort Management Services Ltd</List>
                    <List>• Stantons Ltd</List>
                    <List>• Syndis Ltd</List>
                    <List>• WaterSavr Indian Ocean Ltd</List>
                </ul>
            </div>

            <Paragraph>
                La filiale concernée que vous contactez sera responsable de la conformité de cette politique en
                ce qui concerne les données à caractère personnelles que vous fournissez à cette filiale.
            </Paragraph>

            <Paragraph>
                Pour plus de commodité, la filiale pertinente et/ou le groupe est désigné comme « <strong>Groupe Evaco</strong>
                », « <strong>Compagnie</strong> », « <strong>nous</strong> » ou « <strong>notre</strong> », selon le contexte.
            </Paragraph>

            <Paragraph>
                Chez le Groupe Evaco, nous prenons votre vie privée au sérieux. Nous avons pris les mesures
                nécessaires pour nous assurer que nous sommes conformes aux exigences de la « Data
                Protection Act 2017 » (loi relative à la protection des données à Maurice) (« DPA ») et, le cas
                échéant, du <a href='https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32016R0679%22%20%5Co%20%22%5C%C2%AB%20https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32016R0679%20%5C%C2%BB%20dans%20une%20nouvelle%20fen%C3%AAtre%22%20%5Ct%20%22_blank'> Le règlement (UE) 2016/679</a> du Parlement européen et du Conseil relatif à la
                protection des personnes physiques à l'égard du traitement des données à caractère personnel
                et à la libre circulation de ces données (« RGPD »).
            </Paragraph>

            <Title>
                Portée
            </Title>

            <Paragraph>
                Cette politique de confidentialité fournit des informations sur les questions relatives à votre vie
                privée et explique comment nous collectons, traitons et utilisons les données à caractère
                personnelle que nous recueillons auprès de vous.
            </Paragraph>


            <Title>
                Contenu :
            </Title>

            <ol>
                <List> 1. Comment nous collectons vos données à caractère personnelle </List>
                <List> 2. Les données à caractère personnelle que nous collectons vous concernant </List>
                <List> 3. Utilisation de vos données à caractère personnelle </List>
                <List> 4. Nos fondements juridiques pour l'utilisation de vos données à caractère personnelle </List>
                <List> 5. Stockage de vos données à caractère personnelle</List>
                <List> 6. Partage de vos données à caractère personnelle</List>
                <List> 7. Transfert de données à caractère personnelle en dehors de Maurice </List>
                <List> 8. Vos droits</List>
                <List> 9. Cookies</List>
                <List>10. Autres sites web</List>
                <List>11. Modifications apportées à notre politique de confidentialité</List>
                <List>12. Plaintes</List>
                <List>13. Comment nous contacter</List>
            </ol>


            <div>
                <div className="space-y-2">
                    <Title>
                        1. Comment nous collectons vos données à caractère personnelle
                    </Title>
                    <Paragraph>
                        Nous pouvons collecter vos données à caractère personnelle :
                    </Paragraph>

                    <Paragraph>
                        • lorsque vous vous inscrivez sur notre site web, application web et application mobile,
                        soumettez une demande de renseignements, demandez la fourniture de biens ou services, signez
                        un contrat avec nous, vous abonnez à notre newsletter, répondez à une enquête, effectuez une
                        transaction et/ou remplissez un formulaire sur le site web ; ou
                    </Paragraph>

                    <Paragraph>
                        • lorsque vous interagissez avec nous, nous envoyez un courrier électronique ou nous fournissez
                        des données à caractère personnelle de toute autre manière.
                    </Paragraph>

                    <Paragraph>
                        Selon le but pour lequel vous nous fournissez vos données à caractère personnelle, il peut être
                        nécessaire pour nous, conformément à la loi, de demander des informations personnelles
                        supplémentaires vous concernant, y compris à des fins de lutte contre le blanchiment d'argent
                        et le financement du terrorisme.
                    </Paragraph>

                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        2. Les données à caractère personnelle que nous collectons à votre sujet
                    </Title>
                    <Paragraph>
                        Les types de données à caractère personnelle que nous collectons et traitons à votre sujet
                        peuvent inclure (sans limitation) :
                    </Paragraph>

                    <Paragraph>
                        • des informations telles que votre nom, titre ou poste, votre genre et votre relation avec une
                        personne ; <br />
                        • des informations de contact, y compris votre adresse e-mail et vos numéros de téléphone ;<br />
                        • des informations techniques telles que votre adresse IP et des informations sur vos visites sur
                        nos sites web, application web et application mobile ;<br />
                        • des informations financières telles que des détails de compte bancaire ou de carte de crédit ;<br />
                        • vos centres d'intérêt et préférences ;<br />
                        • les informations que vous nous fournissez dans le cadre de la participation à des réunions et
                        événements, y compris les exigences d'accès et les régimes alimentaires ;<br />
                        • les détails de vos visites dans nos bureaux ;<br />
                        • d'autres informations relatives aux produits et services qui vous intéressent et que vous avez
                        demandés auprès de nous ; et<br />
                        • les informations obtenues dans le cadre d'une due diligence, y compris de nos enquêtes de
                        lutte contre le blanchiment d'argent et le financement du terrorisme que nous sommes tenus de
                        mener, qui peuvent inclure votre côté de crédit, des condamnations pour fraude et autres
                        infractions, et si vous êtes une personne politiquement exposée.
                    </Paragraph>

                    <Paragraph>
                        Si vous avez moins de 16 ans, veuillez ne nous fournir aucune de vos informations à moins d'avoir
                        la permission de votre parent ou tuteur.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        3. Utilisation de vos données à caractère personnelle
                    </Title>
                    <Paragraph>
                        Les objectifs les plus courants pour lesquels nous utiliserons vos données à caractère personnelle
                        sont les suivants :
                    </Paragraph>

                    <Paragraph>
                        • répondre à vos demandes de renseignements ; <br />
                        • répondre à vos demandes de candidature ;<br />
                        • vous fournir un produit ou un service, y compris pour le service après-vente et le support ; <br />
                        • respecter nos obligations légales, qui peuvent nous obliger à fournir vos informations à des
                        organismes d'application de la loi, des régulateurs ou des tribunaux de droit ; <br />
                        • comprendre les produits ou services qui vous intéressent et que vous avez peut-être déjà acquis
                        chez nous ; <br />
                        • effectuer des enquêtes de lutte contre le blanchiment d'argent et le financement du terrorisme
                        lorsque cela est nécessaire de le faire dans le cadre d'une acquisition ou d'une proposition
                        d'acquisition de nos produits ou services par vous ;<br />
                        • vous référer à nos partenaires de confiance (par exemple les banques) si vous nous le
                        demandez, ou si cela est nécessaire pour que nous puissions respecter nos obligations envers
                        vous après l'acquisition ou la proposition d'acquisition de l'un de nos produits ou services ;
                        • à des fins générales d'administration d'entreprise ; et <br />
                        • à des fins d'assurance qualité et d'amélioration de nos produits et services. Avant toute
                        utilisation de ce type, vos informations seront dépersonnalisées de manière à ce qu'elles ne
                        puissent être directement liées à vous, ou votre consentement explicite sera demandé.
                    </Paragraph>

                    <Paragraph>
                        Nous pouvons également souhaiter rester en contact avec vous et utiliser vos données à
                        caractère personnelle à d'autres fins, auquel cas nous vous demanderons votre consentement
                        pour le faire, sauf si nous avons déjà le droit de le faire en raison de l'application d'une autre base
                        légale. Les autres fins pour lesquelles nous pouvons vous demander de donner votre
                        consentement comprennent (sans limitation) :
                    </Paragraph>

                    <Paragraph>
                        • pour améliorer notre site web, application web et application mobile, notre réponse et nos
                        services, notamment en personnalisant les informations et les offres que nous vous présentons;<br />
                        • pour mettre en place une enquête ou solliciter des commentaires ; et<br />
                        • pour vous envoyer nos dernières offres et promotions en relation avec les produits et services
                        offerts par le groupe Evaco, y compris toutes ses filiales (<strong>Matériel Marketing</strong>).
                    </Paragraph>

                    <Paragraph>
                        Si vous avez accepté de recevoir du matériel marketing, vous pouvez toujours vous désinscrire
                        ultérieurement.
                    </Paragraph>

                    <Paragraph>
                        Vous avez le droit à tout moment de nous empêcher de vous contacter à des fins de marketing.
                        Si vous ne souhaitez plus être contacté à des fins de marketing, veuillez-nous en informer aux
                        coordonnées fournies à la fin de cette politique de confidentialité.
                    </Paragraph>

                    <Paragraph>
                        Nous ne vendons aucune donnée à caractère personnelle collectée par nous, et aucune
                        divulgation de vos données à caractère personnelle ne sera faite à une organisation tierce ou à
                        des autorités, sauf si les procédures imposées par la loi ont été suivies, ou si nous sommes obligés
                        de le faire par la loi.
                    </Paragraph>

                    <Paragraph>
                        Dans certaines circonstances, nous pouvons anonymiser vos données à caractère personnelle par
                        pseudonymisation ou cryptage, de sorte que les données à caractère personnelle ne peuvent
                        plus être associées à vous, à des fins de recherche ou statistiques, auquel cas nous pouvons
                        utiliser ces informations indéfiniment sans vous en aviser davantage.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        4. Les fondements juridiques pour l'utilisation de vos données à caractère personnelle
                    </Title>
                    <Paragraph>
                        En fonction des finalités pour lesquelles vos données à caractère personnelle sont utilisées, elles
                        seront traitées sur un ou plusieurs des fondements juridiques suivants :
                    </Paragraph>

                    <Paragraph>
                        • Le traitement est nécessaire à l'exécution d'un contrat auquel vous êtes partie ou afin de
                        prendre des mesures à votre demande avant la conclusion d'un contrat ; <br />
                        • Le traitement est nécessaire pour se conformer à une obligation légale à laquelle nous sommes
                        soumis ;<br />
                        • Le traitement est nécessaire aux fins de nos intérêts légitimes ou de ceux de tout tiers recevant
                        vos informations personnelles, sauf lorsque ces intérêts sont annulés par vos intérêts ou droits
                        et libertés fondamentales.
                    </Paragraph>

                    <Paragraph>
                        La seule exception à ce qui précède concerne l'envoi de Matériel Marketing, que nous ne ferons
                        qu'avec votre consentement, tel que spécifié ci-dessus.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        5. Stockage de vos données à caractère personnelle
                    </Title>
                    <Paragraph>
                        Nous avons mis en place diverses mesures de sécurité pour garantir la sécurité de vos données à
                        caractère personnelle, notamment l'utilisation de pare-feu et de protection antivirus, et nous
                        nous efforcerons de veiller à ce que ces mesures restent adéquates.
                    </Paragraph>

                    <Paragraph>
                        Selon le but pour lequel nous détenons vos données à caractère personnelle et les exigences
                        imposées par la loi, vos données à caractère personnelle seront conservées pendant une certaine
                        période, après quoi elles seront supprimées.
                    </Paragraph>

                    <Paragraph>
                        Le délai de prescription légal à Maurice (c'est-à-dire la période pendant laquelle une partie peut
                        intenter une action en justice contre une autre après la survenue d'un événement) dépend de la
                        nature de la relation et peut aller jusqu'à une période maximale de 10 ans pour certaines
                        questions liées aux biens immobiliers. Selon la nature de notre relation avec vous, nous pouvons,
                        dans ce contexte, choisir également de conserver vos données à caractère personnelle pendant
                        au moins la période de prescription légale pertinente afin de pouvoir défendre ou faire valoir nos
                        droits.
                    </Paragraph>

                    <Paragraph>
                        Bien que nous fassions tous les efforts raisonnables pour sauvegarder vos données à caractère
                        personnelle, vous reconnaissez que l'utilisation d'Internet n'est pas entièrement sécurisée et
                        pour cette raison, nous ne pouvons garantir la sécurité ou l'intégrité de toute information
                        personnelle qui est transférée de vous ou vers vous via Internet.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        6. Partage de vos données à caractère personnelle
                    </Title>
                    <Paragraph>
                        Nous pouvons divulguer vos données à caractère personnelle à nos fournisseurs de services tiers,
                        agents, sous-traitants et autres organisations aux fins de nous fournir des services ou de vous les
                        fournir directement de notre part.
                    </Paragraph>

                    <Paragraph>
                        Ces tiers peuvent inclure des fournisseurs de services de cloud (tels que l'hébergement et la
                        gestion des e-mails) ou d'autres tiers qui nous fournissent des services. Il s'agit notamment, mais
                        sans s'y limiter, de fournisseurs de services informatiques, d'avocats, de banques, d'auditeurs et
                        de tout agent agissant en votre nom.
                    </Paragraph>

                    <Paragraph>
                        Lorsque nous utilisons des fournisseurs de services tiers, nous conserverons le contrôle sur vos
                        données à caractère personnelle et nous resterons entièrement responsables de celles-ci. Nous
                        utiliserons les garanties appropriées requises par la loi applicable pour assurer l'intégrité et la
                        sécurité de vos données à caractère personnelle lors de l'engagement de ces fournisseurs de
                        services.
                    </Paragraph>

                    <Paragraph>
                        Vos données à caractère personnelle peuvent être partagées avec d'autres sociétés du groupe
                        Evaco qui peuvent avoir des produits ou des services qui vous conviennent.
                    </Paragraph>

                    <Paragraph>
                        Nous pouvons également partager vos informations personnelles avec les organismes chargés de
                        l'application de la loi et les autorités de régulation
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        7. Transfert de données à caractère personnelle en dehors de Maurice
                    </Title>
                    <Paragraph>
                        Nous pouvons engager des prestataires de services pour fournir des sauvegardes et des services
                        d'hébergement, qui peuvent être situés à l'étranger
                    </Paragraph>

                    <Paragraph>
                        Dans ces cas, nous prenons des mesures pour garantir que le transfert de vos données à
                        caractère personnelle est protégé et que vos droits à la vie privée sont respectés.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        8. Vos droits
                    </Title>
                    <Paragraph>
                        Vous avez certains droits accordés par la loi. Les droits sont décrits ci-dessous :
                    </Paragraph>

                    <Paragraph>
                        <strong>Le droit d'accès</strong> - vous avez le droit de demander des copies de vos données à caractère
                        personnelle. Nous pouvons vous facturer des frais minimes pour ce service.
                    </Paragraph>

                    <Paragraph>
                        <strong>Le droit de rectification</strong> - vous avez le droit de demander que nous corrigions toute information
                        que vous estimez inexacte. Vous avez également le droit de demander que nous complétions les
                        informations que vous estimez incomplètes.
                    </Paragraph>

                    <Paragraph>
                        <strong>Le droit à l'effacement</strong> - – vous avez le droit de demander que nous effacions vos données à
                        caractère personnelle, dans certaines conditions.
                    </Paragraph>

                    <Paragraph>
                        <strong>Le droit de limiter le traitement</strong> - vous avez le droit de demander que nous limitions le
                        traitement de vos données à caractère personnelle, dans certaines conditions.
                    </Paragraph>

                    <Paragraph>
                        <strong>Le droit d'opposition au traitement</strong> - vous avez le droit de vous opposer à notre traitement de
                        vos données à caractère personnelle, dans certaines conditions.
                    </Paragraph>

                    <Paragraph>
                        <strong>Le droit à la portabilité des données</strong> - vous avez le droit de demander que nous transférions les
                        données que nous avons collectées à une autre organisation, ou directement à vous, dans
                        certaines conditions.
                    </Paragraph>

                    <Paragraph>
                        Si vous faites une demande, nous vous répondrons dans un délai d'un mois. Si vous souhaitez
                        exercer l'un de ces droits, veuillez contacter notre responsable de la protection des données dont
                        les coordonnées figurent en bas de cette politique.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        9. Cookies
                    </Title>
                    <Paragraph>
                        Notre site web, application web et application mobile utilise des cookies, mais votre
                        consentement sera demandé avant que ces cookies soient placés sur votre ordinateur.
                        Nous utilisons des cookies pour collecter des informations sur votre ordinateur à des fins de
                        services et pour fournir des informations statistiques concernant l'utilisation de notre site web,
                        application web et application mobile. Ces informations ne vous identifieront pas
                        personnellement - ce sont des données statistiques sur nos visiteurs et leur utilisation de notre
                        site web, application web et application mobile.
                    </Paragraph>

                    <Paragraph>
                        Si utilisé, le fichier cookie est stocké sur le disque dur de votre ordinateur, car les cookies
                        contiennent des informations qui sont transférées sur le disque dur de votre ordinateur. Ils nous
                        aident à améliorer notre site web, application web et application mobile et les services que nous
                        vous fournissons.<br />
                        Vous pouvez configurer votre navigateur pour refuser tous les cookies ou certains cookies de
                        navigateur, ou pour vous alerter lorsque des sites web, applications web et applications mobile
                        définissent ou accèdent à des cookies.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        10. Autres sites web
                    </Title>
                    <Paragraph>
                        Notre site web, application web et application mobile peut contenir des liens vers des sites web
                        tiers pour votre commodité. Nous n'avons aucun contrôle sur le contenu, les politiques de
                        confidentialité ou les pratiques de ces sites web tiers, ni sur les parties qui contrôlent ces sites
                        web.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        11. Modifications de notre politique de confidentialité
                    </Title>
                    <Paragraph>
                        Nous examinons régulièrement notre politique de confidentialité et publions des versions mises
                        à jour sur cette page web de temps à autre. Cette politique de confidentialité a été mise à jour
                        pour la dernière fois le 28 Juin 2023.
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        12. Plaintes
                    </Title>
                    <Paragraph>
                        Les plaintes concernant toute violation de cette politique de confidentialité et/ou de la législation
                        et des réglementations applicables en matière de protection des données en vigueur peuvent
                        être signalées à notre responsable de la protection des données (coordonnées ci-dessous), ou au
                        Commissaire à la protection des données à Maurice <a href="mailto:dpo@govmu.org">(dpo@govmu.org)</a> .
                    </Paragraph>
                </div>

            </div>

            <div>
                <div className="space-y-2">
                    <Title>
                        13. Comment nous contacter
                    </Title>
                    <Paragraph>
                        Si vous avez des questions sur notre politique de confidentialité, les données que nous détenons
                        à votre sujet, ou si vous souhaitez exercer l'un de vos droits en matière de protection des
                        données, n'hésitez pas à contacter notre responsable de la protection des données par courriel
                        à l'adresse <a href="mailto:dpo@govmu.org">(dpo@govmu.org)</a>; par téléphone au (230) 269 1800 ou par courrier à l'adresse
                        suivante :
                    </Paragraph>
                </div>

            </div>

            <div className='font-bold text-xs md:text-sm'>
                Responsable de la Protection des Données <br />
                Le Groupe Evaco <br />
                Rivière Citron <br />
                20101 Arsenal <br />
                Ile Maurice <br />
            </div>
            <div className="text-[10px] text-center">
                © Conditions d'Utilisation et Politique de Confidentialité
            </div>

        </div>
    )
}

export default CguScreen