import React, { Fragment, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { logout } from "../../features/authSlice"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


function MyPopover(props) {
  const { children, title, isOpen, setIsOpen, t } = props;
  const dispacth = useDispatch();
  const navigate = useNavigate()
  const goodBye = () => {
    dispacth(logout())
    navigate("/login")
  }
  useEffect(() => {
    if (isOpen) {
      setTimeout(function () { setIsOpen(false) }, 5000);
    }

  }, [isOpen == true])
  return (
    <div className='fixed top-9 w-60 max-w-sm  right-0'>
      <Popover className="relative " >
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isOpen}
        >
          <Popover.Panel className="absolute bg-red-500 right-0 z-10 mt-3 w-full max-w-xs">
            {children}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

export default MyPopover