import React, { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import Controls from "../controls/Controls";
import { useFOrm } from "../useFOrm";
//Slices
import { setallUsers, listUserSelector, updateUsersList } from "../../features/listUserSlice";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../DialogBox";
import { XIcon, CheckIcon, CheckCircleIcon, XCircleIcon, TrashIcon } from "@heroicons/react/solid";
import moment from "moment";
import Loading from "../Loading";
import { authSelector } from "../../features/authSlice";
import TableauGrid from '../TableauGrid';
import Switch from '@mui/material/Switch';
import { toast } from "react-toastify";
import * as userService from "../../utils/userService.js"
const initialFvalues = {
  name: '',
  email: '',
  phone: '',
  dob: '',
  address: '',
  level: ''
};


function Users(props) {
  const { t, fetchAllUser, reset } = props;
  const [users, setUsers] = useState();
  const listUser = useSelector(listUserSelector);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const dispacth = useDispatch();
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataSteps, setDataSteps] = useState(false);
  const [emailSelected, setEmailSelected] = useState("");
  const [userselected, setUserSelected] = useState();
  const [checked, setChecked] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [skillIds, setSkillIds] = useState([]);

  function refreshPage() {
    window.location.reload(false);
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    //let test = values.trigger || null;
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? "" : t("error.required")
    if ('email' in fieldValues)
      temp.email = fieldValues.email ? "" : t("error.required")
    if ('phone' in fieldValues)
      temp.phone = fieldValues.phone ? "" : t("error.required")
    if ('dob' in fieldValues)
      temp.dob = fieldValues.dob ? "" : t("error.required")
    if ('address' in fieldValues)
      temp.address = fieldValues.address ? "" : t("error.required")
    setErrors({
      ...temp,
    });
    //console.log("test qq", temp);
    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(
    initialFvalues,
    true,
    validate
  );

  const MyIndicator = ({ value }) => {
    if (!value) return <XCircleIcon className="w-4 h-4 text-red-500" />;
    return <CheckCircleIcon className="w-4 h-4 text-green-500" />;
  };

  const auth = useSelector(authSelector);
  const listU = useSelector(listUserSelector)


  const columnSuperAdmin = [
    { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", hide: true },

    {
      field: "name",
      width: 150, headerName: `${t("label.lastName")}  ${t("label.firstName")}`, headerClassName: "dark:text-slate-100"
    },

    {
      field: "email",
      width: 150,
      headerName: t("label.email"),
      headerClassName: "dark:text-slate-100",
    },

    {
      field: "address",
      width: 100,
      headerName: t('label.address'),
      headerClassName: "dark:text-slate-100",
    },

    {
      field: "phone",
      width: 100,
      headerName: t('label.phone'),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "dob",
      width: 100,
      headerName: t('label.dob'),
      headerClassName: "dark:text-slate-100",
      type: "date",
      renderCell: (cellValues) => {
        if (cellValues.row.dob) {
          return moment(cellValues.row.dob).format("DD/MM/YYYY");
        } else {
          return '-';
        }
      },
    },
    {
      field: "skills",
      width: 250,
      headerName: t('label.skill'),
      headerClassName: "dark:text-slate-100",
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (cellValues) => {
        if (cellValues.row.skills) {
          const skillsArray = cellValues.row.skills
          return (
            <div className='flex space-x-2  p-1 overflow-auto'>
              {skillsArray.map((s) => <div key={s.id} className='border-2 border-teal-300 px-1 rounded-lg text-[10px]'>{s.name} - ({s.category.name})</div>)}
            </div>
          )
        } else {
          return '-';
        }
      },
      valueGetter: (params) => {
        if (params.row.skills) {
          return params.row.skills.map((s) => s.name).join(' · ');
        }
        return '-';
      },
    }, {
      field: "level",
      width: 150,
      headerName: t('label.candidateLevel'),
      headerClassName: "dark:text-slate-100",
      renderCell: (cellValues) => {
        if (cellValues.row.level) {

          const pluralizeEtoile = cellValues.row.level === 1 ? 'étoile' : 'étoiles';
          return (
            <div className='flex space-x-2  p-1 overflow-auto'>
              {cellValues.row.level} {pluralizeEtoile}
            </div>
          )
        } else {
          return 'Pas encore travaillé';
        }
      },
    },

    {
      field: "action",
      headerName: "",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={t("button.edit")}
            onClick={(event) => {
              const dataToShow = listUser.filter((a) => a.id === cellValues.row.id);
              console.log("🚀 ~ file: index.js:155 ~ Users ~ dataToShow:", dataToShow[0])
              setUserSelected(dataToShow[0]);
              setIsOpen(true);
              initialFvalues.name = dataToShow[0].name
              initialFvalues.email = dataToShow[0].email
              initialFvalues.address = dataToShow[0].address
              initialFvalues.phone = dataToShow[0].phone
              initialFvalues.dob = dataToShow[0].dob
              initialFvalues.level = dataToShow[0].level
              setSkillIds(dataToShow[0].skills?.map(s => (s.id)))
            }}
          />
        );
      },
    },


    {
      field: "action2",
      headerName: "",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (

          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
            text={t("button.delete")}
            onClick={(event) => {
              const dataToShow = listUser.filter((a) => a.id === cellValues.row.id);
              setSelectedRow(dataToShow[0]);
              setIsOpenConfirm(!isOpenConfirm)
              //setIsOpen(true);
            }}
          />
        );
      },
    },


  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        // const response = await axiosPrivate.get(`/api/users/genericFindAll`, {
        //     signal: controller.signal
        // });
        const response = await axiosPrivate.get(`/apiv1/bo/getAllMember`, {
          signal: controller.signal,
        });
        //isMounted && setUsers(response.data);

        console.log("USER", response.data);
        if (response.data) {
          // dispacth(setallUsers(response.data.filter(d => (d.type === 'Partener Gold' || d.type === 'Partener')).sort((a, b) => b.id - a.id)));
          isMounted && dispacth(setallUsers(response.data));
        }
      } catch (error) {
        console.log(error)

        // if (error?.response?.status != 403) {
        //   navigate("/login", { state: { from: location }, replace: true });
        // }
      }
    };

    const getSkills = async () => {
      try {
        const response = await axiosPrivate.get(`/api/skill/findAll`, {
          signal: controller.signal,
        });
        isMounted && setSkills(response.data);
        console.log("skills", response.data)
      } catch (error) {
        console.log("🚀 ~ file: index.js:236 ~ getSkills ~ error:", error)
        // if (error.response.status != 403) {
        //   // navigate("/login", { state: { from: location }, replace: true });
        // }
      }
    };

    getUsers();



    getSkills();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const SimpleRender = ({ label, text }) => (
    <div className='flex text-sm space-x-2'>
      <div>{label}: </div>
      <div>{text}</div>
    </div>
  )

  const handleValidate = async () => {

    if (validate()) {
      const dataToUpdate = {
        ...values,
        skillIds: skillIds && skillIds

      }
      console.log("data", dataToUpdate, userselected.id)
      try {
        const response = await axiosPrivate.put(`/apiv1/bo/updateMember/${userselected.id}`, dataToUpdate)
        refreshPage()
        setIsOpen(!isOpen);
        // if (response.data) {
        //   //dispacth(setallUsers(response.data.sort((a, b) => b.id - a.id)));
        //   dispacth(updateUsersList({ uid: response.data.id, data: response.data }));
        //   setUserSelected()
        //   setIsOpen(!isOpen);
        //   //setSkillIds([])
        // }

      } catch (error) {
        console.log("🚀 ~ file: index.js:279 ~ handleValidate ~ error:", error)

      }

      // open 1st modal are you sure
    }
  };


  // useEffect(() => {
  //   if (!userselected) {
  //     setChecked(userselected.isActived)
  //   }
  // }, [userselected])

  useEffect(() => {
    if (isOpen == false) {
      initialFvalues.name = ""
      initialFvalues.email = ""
      initialFvalues.tel = ""
      initialFvalues.address = ""
      initialFvalues.phone = ""
      initialFvalues.level = ""
      initialFvalues.dob = ""
    }
  }, [isOpen])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDeleteAccount = async (id) => {
    try {
      setIsLoading(true)
      const respAfterDeleteMember = await axiosPrivate.delete(`/apiv1/bo/removeAccount/${id}`);

      console.log(id)
      dispacth(setallUsers(listUser.filter(l => l.id != id)));
      setIsLoading(false)
      //setIsOpen(false)
      setIsOpenConfirm(false)
      //refreshPage()
      toast.success("Le compte supprimé.")

    } catch (error) {
      console.log("err", error)
      setIsLoading(false)
      toast.error("Erreur")
    }

  }
  const formLogin = [
    {
      type: "text",
      name: "name",
      label: t('label.name'),
      placeholder: "John",
      password: false,
      required: true,
    },
    {
      type: "email",
      name: "email",
      label: t("label.email"),
      placeholder: "email@fastpay.com",
      password: false,
      required: true,
    },

    {
      type: "number",
      name: "phone",
      label: t('label.phone'),
      placeholder: "58000000",
      password: false,
      required: true,
    },
    {
      type: "date",
      name: "dob",
      label: t('label.dob'),
      placeholder: "58000000",
      password: false,
      required: true,
    },
    {
      type: "text",
      name: "address",
      label: t('label.address'),
      placeholder: "Grand bay",
      password: false,
      required: true,
    },
  ];
  const handleSkillChange = (id) => {
    console.log("🚀 ~ file: index.js:353 ~ handleSkillChange ~ id:", id)

    if (skillIds.length > 0 && skillIds.includes(id)) {
      setSkillIds(skillIds.filter((skillId) => skillId !== id)); // Remove the skill id
    } else {
      setSkillIds([...skillIds, id]); // Add the skill id
    }
  };
  return (
    <>

      <DialogBox
        isOpen={isOpenConfirm}
        setIsOpen={setIsOpenConfirm}
        title={'Êtes-vous sûr(e) ?'}
        customClassName={"md:w-1/3 w-full min-h-[10px]"}
      >
        <div className='flex flex-col items-center justify-center space-y-4 text-sm'>
          <TrashIcon className='w-20 h-20 text-red-400' />

          <div className='text-lg'>
            Si vous supprimez le compte de <span className='text-green-600 text-lg font-bold'> {selectedRow?.name} </span>, ce membre n'aura plus accès à l'application</div>
          <div>
            <span className='text-red-500 font-semibold text-lg'>Cette action est irréversible.</span>
          </div>

          <div className='flex justify-center w-full space-x-3'>
            <Controls.Button color={'bg-slate-300 text-black'} text="Annuler" onClick={() => setIsOpenConfirm(false)} isLoading={isLoading} />
            <Controls.Button color={'bg-red-500 text-white'} text="Continuer" onClick={() => handleDeleteAccount(selectedRow.id)} isLoading={isLoading} />
          </div>

        </div>

      </DialogBox>
      <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} title={userselected && userselected.pseudo}>
        <div className="flex flex-col gap-y-2">
          <SimpleRender label={'Modifcation'} text={userselected && userselected.name} />
          <div className='flex flex-col p-2 space-y-4 items-center'>
            {formLogin.map((item, i) => (
              <Controls.Input
                fullWidth={true}
                label={item.label}
                value={values[item.name]}
                onChange={handleInputChange}
                name={item.name}
                type={item.type}
                error={errors[item.name]}
              />
            ))}

            <Controls.Select
              name="level"
              label={'Niveau du Candidat'}
              value={values.level}
              onChange={handleInputChange}
              options={userService.getUserStar()}
              error={errors.level}
            />


            <div className='font-semibold'>
              {t('label.skill')}
            </div>
            <div className='grid grid-rows-6  grid-flow-col gap-2'>

              {skills.map((item, i) => (
                <Controls.Checkbox
                  key={i}
                  name={item.id}
                  label={`${item.name} (${item?.category?.name})`}
                  value={skillIds && skillIds?.includes(item.id)}
                  onChange={(e) => handleSkillChange(e.target.name)}
                />
              ))}
            </div>
            {/* <div>Active</div>

            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            /> */}
          </div>
          <div className='flex justify-end w-full p-2'>
            <Controls.Button text="Confirmer" onClick={handleValidate} />
          </div>

        </div>
      </DialogBox>

      {listUser && listUser ? <TableauGrid column={columnSuperAdmin} dataRows={listUser && listUser} showQuickFilter fileName={'Membre'} /> : <Loading />}
    </>
  );
}

export default Users;
