import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = []

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        addJob: (state, action) => {
            return [...state, action.payload];
        },
        removeJob: (state, action) => {
            return state.filter(item => item.id !== action.payload);
        },
        setAlljobs: (state, action) => {
            return action.payload;
        },
        updateJobs: (state, action) => {
            const changedIdx = state.findIndex(u => (u.id == action.payload.id))
            if (changedIdx === -1) {
                // Return the state as is if the item with the specified ID is not found
                return state;
            }

            //const newArray = [ current(state)]
            return [
                ...state.slice(0, changedIdx),
                {
                    ...state[changedIdx],
                    ...action.payload
                },
                ...state.slice(changedIdx + 1)]
        }
    }
});

export const { addJob, setAlljobs, updateJobs, removeJob } = jobSlice.actions
export const jobSelector = (state) => state.job;
export default jobSlice.reducer
