import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TopBar from '../components/TopBar';
import Sidebar from '../components/Sidebar';
import * as sideMenus from "../utils/sideMenus";
import { useLocation, useNavigate } from 'react-router-dom';
import Controls from '../components/controls/Controls';
import TableauGrid from '../components/TableauGrid';
import Loading from '../components/Loading';
import DialogBox from '../components/DialogBox';
import { useFOrm } from '../components/useFOrm'
import { addJob, jobSelector, removeJob, setAlljobs, updateJobs } from '../features/jobSlice';
import { toast } from 'react-toastify';
import { TrashIcon } from '@heroicons/react/solid';
import { UserGroupIcon } from '@heroicons/react/outline';
const initialFvalues = {
    title: "",
    description: "",
    categoryId: "",
    skillId: "",
    startDate: "",
    endDate: "",
    price: "",
    locationId: "",
    experienceId: "",
    interventionPeriod: 0
}
function JobListScreen(props) {
    const { t } = props;
    const axiosPrivate = useAxiosPrivate();
    const dispacth = useDispatch();
    // const [jobList, setJobList] = useState([]);
    const jobList = useSelector(jobSelector)
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpenModal, setIsModalOpen] = useState(false);
    const [category, setCategory] = useState([]);
    const [skills, setSkills] = useState([]);
    const [locations, setLocations] = useState([]);
    const [expericences, setExperiences] = useState([]);
    const [edit, setEdit] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [allApplications, setAllApplications] = useState([]);
    const [whoApply, setWhoApply] = useState([]);
    const [isListApply, setIsListApply] = useState(false);
    const [areUSure, setAreUSure] = useState(false);
    const [jobToRemove, setJobToRemove] = useState('');
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('title' in fieldValues)
            temp.title = fieldValues.title ? "" : t("error.required")
        if ('description' in fieldValues)
            temp.description = fieldValues.description ? "" : t("error.required")
        if ('categoryId' in fieldValues)
            temp.categoryId = fieldValues.categoryId ? "" : t("error.required")
        if ('skillId' in fieldValues)
            temp.skillId = fieldValues.skillId ? "" : t("error.required")
        if ('startDate' in fieldValues)
            temp.startDate = String(fieldValues.startDate) != "" ? "" : t("error.required")
        if ('endDate' in fieldValues)
            temp.endDate = String(fieldValues.endDate) != "" ? "" : t("error.required")
        if ('locationId' in fieldValues)
            temp.locationId = fieldValues.locationId != "" ? "" : t("error.required")
        if ('experienceId' in fieldValues)
            temp.experienceId = fieldValues.experienceId != "" ? "" : t("error.required")
        if ('price' in fieldValues)
            temp.price = fieldValues.price != "" ? "" : t("error.required")

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "" || x == []);
    }

    useEffect(() => {
        if (isOpenModal == false) {
            initialFvalues.title = ""
            initialFvalues.description = ""
            initialFvalues.skillId = ""
            initialFvalues.categoryId = ""
            initialFvalues.startDate = ""
            initialFvalues.endDate = ""
            initialFvalues.price = ""
            initialFvalues.locationId = ""
            initialFvalues.experienceId = ""
            initialFvalues.interventionPeriod = 0
        }

    }, [isOpenModal])

    const {
        values,
        setValues,
        errors,
        setErrors,
        resetForm,
        handleInputChange
    } = useFOrm(initialFvalues, true, validate)
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getAllJobs = async () => {
            try {
                const resp = await axiosPrivate.get(`/apiV1/bo/getAllJobs`, {
                    signal: controller.signal,
                });
                console.log("++++", resp.data)
                //isMounted && setJobList(resp?.data?.sort((a, b) => b.id - a.id));
                isMounted && dispacth(setAlljobs(resp?.data?.sort((a, b) => b.id - a.id)))
            } catch (error) {
                if (error.response.status != 403) {
                    navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };
        getAllJobs()

        const getSkills = async () => {
            try {
                const response = await axiosPrivate.get(`/api/skill/findAll`, {
                    signal: controller.signal,
                });
                isMounted && setSkills(response.data);
                console.log("skills", response.data)
            } catch (error) {
                if (error.response.status != 403) {
                    // navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };
        getSkills()

        const getCategories = async () => {
            try {
                const response = await axiosPrivate.get(`/api/category/findAll`, {
                    signal: controller.signal,
                });
                isMounted && setCategory(response.data);

            } catch (error) {
                if (error.response.status != 403) {
                    // navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };
        getCategories()
        const getLocations = async () => {
            try {
                const response = await axiosPrivate.get(`/api/location/findAll`, {
                    signal: controller.signal,
                });
                isMounted && setLocations(response.data);

            } catch (error) {
                if (error.response.status != 403) {
                    // navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };
        getLocations()
        const getExperiences = async () => {
            try {
                const response = await axiosPrivate.get(`/api/skill/allexp`, {
                    signal: controller.signal,
                });
                isMounted && setExperiences(response.data);

            } catch (error) {
                if (error.response.status != 403) {
                    // navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };
        getExperiences()



        const getJobApply = async () => {
            try {
                const response = await axiosPrivate.get(`/api/job/findAllApply`, {
                    signal: controller.signal,
                });
                isMounted && setAllApplications(response.data);
                console.log("getJobApply", response.data)
            } catch (error) {
                if (error.response.status != 403) {
                    // navigate("/login", { state: { from: location }, replace: true });
                }
            }
        };
        getJobApply()
        return () => {
            isMounted = false;
            controller.abort();
        };

    }, [])

    const columns = [
        {
            field: "id",
            headerName: "id",
            hide: true,
        },
        {
            field: "reference",
            headerName: "Référence",
        },
        // {
        //     field: "createdAt",
        //     headerName: "Created date",
        //     type: "date",
        //     width: 150,
        //     renderCell: (cellValues) => {
        //         if (cellValues.row.createdAt) {
        //             return moment(cellValues.row.createdAt).format("DD/MM/YYYY");
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        {
            field: "title",
            headerName: 'Poste',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.title) {
                    return cellValues.row.title
                } else {
                    return '-';
                }
            },
        },

        {
            field: "description",
            headerName: t('label.description'),
            width: 200,
            renderCell: (cellValues) => {
                if (cellValues.row.description) {
                    return cellValues.row.description
                } else {
                    return '-';
                }
            },
        },

        {
            field: "interventionPeriod",
            headerName: t('label.interventionPeriod'),
            width: 100,
            type: "number"
        },





        {
            field: "startDate",
            headerName: t('label.startDate'),
            width: 100,
            type: "date",
            renderCell: (cellValues) => {
                if (cellValues.row.startDate) {
                    return moment(cellValues.row.startDate).format("DD/MM/YYYY");
                } else {
                    return '-';
                }
            },
        },
        {
            field: "endDate",
            headerName: t('label.endDate'),
            width: 100,
            type: "date",
            renderCell: (cellValues) => {
                if (cellValues.row.endDate) {
                    return moment(cellValues.row.endDate).format("DD/MM/YYYY");
                } else {
                    return '-';
                }
            },

        },
        {
            field: "price",
            headerName: t('label.price'),
            width: 100,
            type: "number"

        },
        {
            field: "skillId",
            headerName: 'Compétence',
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.skill) {
                    return cellValues.row.skill.name
                } else {
                    return '-';
                }
            },
            valueGetter: (params) => {
                if (params.row.skill) {
                    return params.row.skill.name
                }
                return '-';
            },
        },
        {
            field: "locationId",
            headerName: t('label.location'),
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.location) {
                    return cellValues.row.location.name
                } else {
                    return '-';
                }
            },
            valueGetter: (params) => {
                if (params.row.location) {
                    return params.row.location.name
                }
                return '-';
            },
        },
        {
            field: "experienceId",
            headerName: t('label.experience'),
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.experience) {
                    return cellValues.row.experience.name
                } else {
                    return '-';
                }
            },
            valueGetter: (params) => {
                if (params.row.experience) {
                    return params.row.experience.name
                }
                return '-';
            },
        },
        {
            field: "userIds",
            headerName: 'Nombre de candidats',
            width: 100,
            type: "number",
            renderCell: (cellValues) => {
                if (cellValues.row.userIds) {
                    return cellValues.row.userIds.length
                } else {
                    return '0';
                }
            },
            valueGetter: (params) => {
                if (params.row.userIds) {
                    return params.row.userIds.length
                }
                return '-';
            },

        },
        {
            field: "werwe",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-green-500 dark:text-slate-100 hover:text-green-600"}
                        text={'Candidat(s)'}
                        onClick={async (event) => {
                            console.log("cellValues.row.id", cellValues.row.id, allApplications)
                            setIsListApply(!isListApply)
                            const dataToShow = jobList.filter((a) => a.id === cellValues.row.id);
                            setSelectedRow(dataToShow[0]);
                            const result = allApplications?.filter(a => a.jobofferId == cellValues.row.id)
                            console.log("result", result)
                            setWhoApply(result)

                        }}
                    />
                );
            },
        },

        {
            field: "action",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-primary dark:text-slate-100 hover:text-secondary"}
                        text={t('button.edit')}
                        onClick={(event) => {
                            setEdit(true)
                            const dataToShow = jobList.filter((a) => a.id === cellValues.row.id);
                            initialFvalues.title = dataToShow[0].title
                            initialFvalues.description = dataToShow[0].description
                            initialFvalues.skillId = dataToShow[0].skillId
                            initialFvalues.categoryId = dataToShow[0].skill.categoryId

                            initialFvalues.startDate = dataToShow[0].startDate
                            initialFvalues.endDate = dataToShow[0].endDate
                            initialFvalues.price = dataToShow[0].price
                            initialFvalues.locationId = dataToShow[0].locationId
                            initialFvalues.experienceId = dataToShow[0].experienceId
                            initialFvalues.interventionPeriod = dataToShow[0].interventionPeriod
                            // console.log("dasdadasdasd", dataToShow)

                            // initialFvalues.memberid = dataToShow[0]?.member?.id || 0
                            // initialFvalues.url = dataToShow[0]?.url
                            // initialFvalues.screenName = dataToShow[0]?.screenName
                            // initialFvalues.pageUrl = dataToShow[0].pageUrl || null
                            // initialFvalues.level = dataToShow[0]?.level
                            // initialFvalues.state = dataToShow[0].state ? 1 : 0
                            setSelectedRow(dataToShow[0]);
                            // setIsOpen(true);
                            setIsModalOpen(true)
                        }}
                    />
                );
            },
        },



        {
            field: "action2",
            headerName: '',
            sortable: false,
            filterable: false,
            disableExport: true,
            headerClassName: "dark:text-slate-100",
            renderCell: (cellValues) => {
                return (
                    <Controls.Button
                        xsFont
                        small
                        variant="none"
                        color={"text-red-500 dark:text-slate-100 hover:text-secondary"}
                        text={t('button.delete')}
                        onClick={async (event) => {
                            // await deletBanner(cellValues.row.id)
                            // dispacth(removeJob(cellValues.row.id))
                            // await axiosPrivate.delete(`/api/job/${cellValues.row.id}`)
                            // toast.success("Job Supprimé")
                            setAreUSure(true)
                            const dataToShow = jobList.filter((a) => a.id === cellValues.row.id);
                            setJobToRemove(dataToShow[0])
                        }}
                    />
                );
            },
        },
    ];



    const openModalAdd = async () => {
        try {
            setEdit(false)
            setIsModalOpen(!isOpenModal)

        } catch (error) {
            console.log("test", error)
        }
    }

    const addNewJobOffer = async () => {
        try {
            if (validate()) {
                const { data } = await axiosPrivate.post('/api/job/new', values)
                dispacth(addJob(data))
                setIsModalOpen(!isOpenModal)
            }
        } catch (error) {
            console.log("test", error)
        }
    }


    const editOffer = async () => {
        try {
            if (validate()) {
                const { data } = await axiosPrivate.put(`/api/job/${selectedRow.id}`, values)
                console.log("values edietd", data)
                dispacth(updateJobs(data))
                setIsModalOpen(!isOpenModal)
            }

        } catch (error) {

        }
    }

    const handelCancel = async () => {
        resetForm()
        setIsModalOpen(!isOpenModal)
    }

    const handelDelete = async (id) => {
        dispacth(removeJob(id))
        await axiosPrivate.delete(`/api/job/${id}`)
        toast.success("Job Supprimé")
        setAreUSure(false)
    }

    useEffect(() => {
        if (values.endDate || values.startDate) {
            const startDateFormat = moment(values.startDate, 'YYYY-MM-DD');
            const endDateFormat = moment(values.endDate, 'YYYY-MM-DD');
            const interventionPeriodInDays = endDateFormat.diff(startDateFormat, 'days');
            const e = {
                target: {
                    name: "interventionPeriod",
                    value: interventionPeriodInDays
                }
            }
            handleInputChange(e)
        }

    }, [values.endDate, values.startDate])

    return (
        <>
            <TopBar menus={sideMenus.menusAdmin(t)} />
            <div className="flex flex-row flex-grow">
                <Sidebar menus={sideMenus.menusAdmin(t)} />
                <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
                    <div className="flex flex-col w-full p-4 ">
                        <div className="flex-none h-auto">
                            <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.jobOffer`)}</h1>

                            <hr />
                        </div>

                        <DialogBox
                            isOpen={isListApply}
                            setIsOpen={setIsListApply}
                            title={`Liste des candidats , Ref: ${selectedRow?.reference}`}
                            customClassName={"md:w-1/2 w-full min-h-[10px] min-h-[400px]"}
                        >

                            <div className='grid grid-cols-3 gap-2'>
                                {whoApply.length > 0 ? whoApply?.map(s => (s.user)).map((w, i) => (
                                    <div key={i} className='flex flex-col space-y-1 justify-start items-start w-full text-sm border-2 border-teal-400 rounded-lg p-2 shadow-sm bg-teal-50'>

                                        <div className='text-xs'>
                                            <strong>Nom Prenom:</strong> {w.name}
                                        </div>
                                        <div className='text-xs'>
                                            <strong>Email :</strong> {w.email}
                                        </div>
                                        <div className='text-xs'>
                                            <strong>Tel:</strong> {w.phone}
                                        </div>
                                        <div className='text-xs'>
                                            <strong>Address:</strong> {w.address}
                                        </div>
                                        <div className='text-xs'>
                                            <strong>Date de naissance:</strong> {moment(w.dob, 'YYYY-MM-DD').format("DD/MM/YYYY")}
                                        </div>


                                        <div className='text-xs'>
                                            <strong>Compétences:</strong>
                                            <div className=''>
                                                {w.skills?.sort((a, b) => a.name.localeCompare(b.name)).map(s => (
                                                    <div key={s.id} className='text-xs'>
                                                        {s.name} - ({s.category.name})
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                )) :
                                    <div className=''>
                                        Aucun résultat
                                    </div>
                                }
                            </div>
                            {/* <div className='flex justify-center w-full space-x-3'>
                                    <Controls.Button color={'bg-green-500 text-white'} text="Continuer" />
                                </div> */}

                        </DialogBox>

                        <DialogBox
                            isOpen={areUSure}
                            setIsOpen={setAreUSure}
                            title={'Êtes-vous sûr(e) ?'}
                            customClassName={"md:w-1/3 w-full min-h-[10px] min-h-[400px]"}
                        >
                            <div className='flex flex-col items-center justify-center space-y-5'>
                                <TrashIcon className='w-20 h-20 text-red-400' />
                                <div className='text-center'>
                                    Si vous supprimez cette offre d'emploi <strong>{jobToRemove?.reference}</strong>,  toutes les candidatures associées à cette offre seront automatiquement supprimées de la base de données. Cependant, cela n'entraînera pas la suppression du compte du membre qui a postulé. .
                                </div>

                                <strong className='text-red-500'>Cette action est irréversible</strong>

                                <div className='flex justify-center w-full space-x-3'>
                                    <Controls.Button color={'bg-slate-300 text-black'} text="Annuler" onClick={() => setAreUSure(false)} />

                                    <Controls.Button color={'bg-red-500 text-white'} text="Supprimer" onClick={() => handelDelete(jobToRemove.id)} />
                                </div>
                            </div>
                        </DialogBox>
                        <DialogBox
                            isOpen={isOpenModal}
                            setIsOpen={setIsModalOpen}
                            title={t('label.job')}
                            customClassName={"md:w-1/2 w-full min-h-[300px]"}
                        >
                            <div className="h-fit bg-slate-100 my-2 p-2">

                                <div className='flex flex-col gap-y-5'>

                                    <Controls.Input
                                        fullWidth={true}
                                        label={t('label.jobTitle')}
                                        value={values.title || ""}
                                        onChange={handleInputChange}
                                        name="title"
                                        error={errors.title}
                                    />

                                    <Controls.Input
                                        fullWidth={true}
                                        label={t('label.description')}
                                        value={values.description || ""}
                                        onChange={handleInputChange}
                                        name="description"
                                        error={errors.description}
                                    />


                                    <div className='flex space-x-3'>

                                        <Controls.Select
                                            name="categoryId"
                                            label={t('label.category')}
                                            value={values.categoryId}
                                            onChange={handleInputChange}
                                            options={category.map(l => {
                                                return {
                                                    id: l.id,
                                                    title: `${l.name}`
                                                }
                                            })}
                                            error={errors.categoryId}
                                        />


                                        <Controls.Select
                                            name="skillId"
                                            label={t('label.skill')}
                                            value={values.skillId}
                                            onChange={handleInputChange}
                                            options={values.categoryId ? skills.filter(s => s.category.id == values.categoryId).map(l => ({ id: l.id, title: `${l.name}` })) : []}
                                            error={errors.skillId}
                                        />

                                        <Controls.Select
                                            name="experienceId"
                                            label={t('label.experience')}
                                            value={values.experienceId}
                                            onChange={handleInputChange}
                                            options={expericences.map(l => {
                                                return {
                                                    id: l.id,
                                                    title: `${l.name}`
                                                }
                                            })}
                                            error={errors.experienceId}
                                        />

                                        <Controls.Select
                                            name="locationId"
                                            label={t('label.location')}
                                            value={values.locationId}
                                            onChange={handleInputChange}
                                            options={locations.map(l => {
                                                return {
                                                    id: l.id,
                                                    title: `${l.name}`
                                                }
                                            })}
                                            error={errors.locationId}
                                        />
                                    </div>


                                    <div className='flex space-x-3'>

                                        <Controls.Input
                                            type="date"
                                            fullWidth={true}
                                            label={t('label.startDate')}
                                            value={values.startDate}
                                            onChange={handleInputChange}
                                            name="startDate"
                                            error={errors.startDate}
                                        />


                                        <Controls.Input
                                            type="date"
                                            fullWidth={true}
                                            label={t('label.endDate')}
                                            value={values.endDate}
                                            onChange={handleInputChange}
                                            name="endDate"
                                            min={values.startDate}
                                            error={errors.endDate}
                                        />


                                    </div>
                                    <Controls.Input
                                        fullWidth={true}
                                        label={t('label.interventionPeriod')}
                                        value={values.interventionPeriod || ""}
                                        onChange={handleInputChange}
                                        name="interventionPeriod"
                                        error={errors.interventionPeriod}
                                        type={"number"}
                                        min={0}
                                    />

                                    <Controls.Input
                                        fullWidth={true}
                                        label={t('label.price')}
                                        value={values.price || ""}
                                        onChange={handleInputChange}
                                        name="price"
                                        error={errors.price}
                                        type={"number"}
                                        min={0}
                                    />


                                    <div className='flex justify-end w-full space-x-3'>
                                        <Controls.Button small xsFont color={'bg-slate-300 text-black'} text="Annuler" onClick={handelCancel} />
                                        <Controls.Button small xsFont color={`${edit ? "bg-yellow-500" : "bg-green-500 "} text-white`} text="Sauvegarder" onClick={edit ? editOffer : addNewJobOffer} />
                                    </div>
                                </div>




                            </div>

                        </DialogBox>

                        <div className='flex justify-end mb-3'>
                            <Controls.Button small xsFont text={t('button.add')} onClick={openModalAdd} />
                        </div>
                        <div className="h-[76vh] bg-slate-100">
                            {jobList && jobList ? <TableauGrid column={columns} dataRows={jobList && jobList} showQuickFilter fileName={'Job'} /> : <Loading />}
                        </div>
                    </div >


                </div >

            </div >
        </>
    )
}

export default JobListScreen