import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = []

export const listUserSlice = createSlice({
    name: 'listUser',
    initialState,
    reducers: {
        setallUsers: (state, action) => {
            return action.payload;
        },
        updateUsersList: (state, action) => {
            const changedIdx = state.findIndex(u => u.id === action.payload.uid);
            console.log("action.payload.data", action.payload.data)
            if (changedIdx !== -1) {


                return [
                    ...state.slice(0, changedIdx),
                    {
                        ...state[changedIdx],
                        skills: action.payload.data.skills
                    }
                    ,
                    ...state.slice(changedIdx + 1)
                ];
            }

            return state;
        }
    }
});

export const { setallUsers, filterUsersByDate, updateUsersList } = listUserSlice.actions
export const listUserSelector = (state) => state.listUser;
export default listUserSlice.reducer
