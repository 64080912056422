import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
// import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import LoginScreen from "./screens/LoginScreen";
import UnauthorizedScreen from "./screens/UnauthorizedScreen";




//Forgot password
import PageNotFound from "./components/pageNotFound";
import Layout from "./components/Layout";

import CpScreen from "./screens/CpScreen";
import MaintenanceScreen from "./screens/MaintenanceScreen";
import ReportScreen from './screens/ReportScreen';
import BannerScreen from './screens/BannerScreen';
import CguScreen from './screens/CguScreen';
import GroupScreen from './screens/GroupScreen';
import JobListScreen from './screens/JobListScreen';


const ROLES = {
  SuperAdmin: "0",
  Admin: "3",
};

function App() {

  const { t } = useTranslation();

  const maintenance = false;
  return (
    <>
      {maintenance ? (
        <MaintenanceScreen />
      ) : (
        <>
          <BrowserRouter>
            <ToastContainer hideProgressBar={true} />
            <Routes>
              <Route path="login" element={<LoginScreen t={t} />} />
              <Route exact path="/" element={<LoginScreen t={t} />} />
              <Route path="/" element={<Layout t={t} />}>
                {/* Public Route */}
                <Route path="unauthorized" element={<UnauthorizedScreen />} />
                <Route path="/privacy" element={<CguScreen t={t} />} />

                <Route element={<PersistLogin />}>
                  {/* Super admin Route */}
                  <Route element={<RequireAuth allowedRole={[ROLES.SuperAdmin]} />}>
                    <Route path="/hello" element={<CpScreen t={t} />} />
                    <Route path="/jobs" element={<JobListScreen t={t} />} />
                    {/* <Route path="/banners" element={<BannerScreen t={t} />} />
                    <Route path="/groups" element={<GroupScreen t={t} />} />
                    <Route path="/reports" element={<ReportScreen t={t} />} /> */}

                  </Route>

                  {/* Client Route */}
                  {/* <Route element={<RequireAuth allowedRole={[ROLES.Support, ROLES.SuperAdmin, ROLES.Admin]} />}>
                    <Route path="/support/user/:id" element={<OneUser t={t} />} />
                    <Route path="/rebalancing/:id" element={<ClientRebalancingScreen t={t} />} />
                    <Route path="/evolution/:id" element={<SimulatorScreen t={t} />} />
                  </Route>

                  <Route element={<RequireAuth allowedRole={[ROLES.Client]} />}>
                    <Route path="/rebalancing" element={<ClientRebalancingScreen t={t} />} />
                    <Route path="/evolution" element={<SimulatorScreen t={t} />} />
                  </Route> */}

                </Route>

                {/* not found */}
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
          <CssBaseline />
        </>
      )}
    </>
  );
}

export default App;
