import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../features/authSlice";
import themeReducer from "../features/themeSlice";
import jobSlice from '../features/jobSlice';
import listUserSlice from '../features/listUserSlice';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "theme"],
};

const roodReducer = combineReducers({
  auth: authReducer,
  job: jobSlice,
  theme: themeReducer,
  listUser: listUserSlice

});

export default persistReducer(persistConfig, roodReducer);
